
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    
import React from "react";
import Homepage from "../components/Homepage";

export default function Home({ storeCode }) {
  return <Homepage storeCode={storeCode} />;
}

 async function _getServerSideProps({ req }) {
  return {
    props: {
      storeCode: req.headers["x-store-code"],
    },
  };
}


    export async function getServerSideProps(ctx) {
        let res = _getServerSideProps(ctx)
        if(typeof res.then === 'function') res = await res
        return {
          
          ...res,
          props: {
            ...(res.props || {}),
            ...(await __loadNamespaces({
              ...ctx,
              pathname: '/index',
              loaderName: 'getServerSideProps',
              ...__i18nConfig,
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
            }))
          }
        }
    }
  