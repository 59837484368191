import { useRouter } from "next/router";
import Image from "next/image";
import useTranslation from "next-translate/useTranslation";

export default function ProductItem({ product }) {
  const router = useRouter();
  const { t } = useTranslation("home");

  const baseUrl = `${location.protocol}//${process.env.NEXT_PUBLIC_IMAGE_DOMAIN}/pub/media/catalog/product`;

  return (
    <div className="relative pb-24 border border-primary-light min-h-full">
      <div className="aspect-w-2 aspect-h-1 w-full relative">
        {product.media_gallery_entries[0] && (
          <Image
            src={`${baseUrl}${product.media_gallery_entries[0].file}`}
            layout="fill"
            objectFit="cover"
            alt={product.name}
          />
        )}
      </div>
      <div className="text-center mt-8">
        <h1 className="text-lg lg:text-xl">{product.name}</h1>
        <p
          dangerouslySetInnerHTML={{
            __html: Array.isArray(product.custom_attributes)
              ? product.custom_attributes[0]?.value
              : Object.values(product.custom_attributes)[0].value,
          }}
          className="text-center px-8 mt-6 text-xs lg:text-sm"
        />
        <button
          className="absolute bottom-6 left-1/2 -translate-x-1/2 w-max bg-secondary hover:bg-secondary-dark transition text-white mt-10 px-6 lg:px-10 py-3 lg:py-4 uppercase text-xs"
          onClick={() =>
            router.push({
              pathname:
                router.locale === "en" ? "/customization" : "/personnalisation",
              query: { sku: product.sku },
            })
          }
        >
          {t("button_customize_product")}
        </button>
      </div>
    </div>
  );
}
