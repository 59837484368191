export default function ProductItemSkeleton({}) {
  return (
    <div className="relative pb-10 border border-primary-light min-h-full animate-pulse">
      <div className="aspect-w-2 aspect-h-1 w-full bg-tertiary" />
      <div className="mt-8 flex flex-col items-center justify-center">
        <div className="w-1/2 h-4 bg-tertiary" />
        <div className="w-3/4 h-4 bg-tertiary mt-8" />
        <div className="w-3/5 h-4 bg-tertiary mt-2" />
        <div className="w-3/6 h-4 bg-tertiary mt-2" />
      </div>
      <div className="flex justify-center mt-10">
        <div className="w-3/5 h-12 bg-secondary mt-2" />
      </div>
    </div>
  );
}
