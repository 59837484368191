import React, { useEffect, useState } from "react";
import { getCustomizableProducts } from "../services/apiService";
import Image from "next/image";
import ProductList from "./Product/ProductList";
import useTranslation from "next-translate/useTranslation";
import Meta from "./Meta";

export default function Homepage({ storeCode }) {
  const [products, setProducts] = useState(null);

  const { t } = useTranslation("home");

  useEffect(() => {
    if (!products && storeCode) {
      getCustomizableProducts(storeCode).then((data) => {
        if (data) {
          setProducts(data.items);
        }
      });
    }
  }, [products, storeCode]);

  return (
    <>
      <Meta
        title={t("title_customize")}
        description={t("title_create_your_carpet")}
      />
      <div className="relative h-full">
        <div className="bg-primary py-2 lg:py-4">
          <h1 className="text-white text-center">{t("title_create_your_carpet")}</h1>
        </div>
        <div className="container mx-auto mt-20 relative text-primary-dark px-4">
          <ProductList products={products} storeCode={storeCode} />
        </div>
      </div>
    </>
  );
}
