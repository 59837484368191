import React from "react";
import Head from "next/head";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";
import { getCanonicalAppUrl } from "../services/apiService";

export default function Meta({ title, description, image }) {
  const { t, lang } = useTranslation("common");
  const { asPath } = useRouter();

  const canonicalUrl = getCanonicalAppUrl(asPath, lang);

  const siteTitle = title
    ? t("meta.title_full", { title: title.replace(/(<([^>]+)>)/gi, "") })
    : t("meta.title");
  const siteDescription = description
    ? description.replace(/(<([^>]+)>)/gi, "")
    : t("meta.description");

  return (
    <Head>
      <title>{siteTitle}</title>
      <meta key="title" name="title" content={siteTitle} />
      <meta key="description" name="description" content={siteDescription} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={canonicalUrl} />
      <meta key="og:title" property="og:title" content={siteTitle} />
      <meta
        key="og:description"
        property="og:description"
        content={siteDescription}
      />
      {image ? (
        <meta key="og:image" property="og:image" content={image} />
      ) : (
        <>
          <meta property="og:image" content="/images/mtp-homepage-banner.jpg" />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="627" />
        </>
      )}
      <meta name="msapplication-TileColor" content="#21365E" />
      <meta name="theme-color" content="#FFFFFF" />
      <link rel="canonical" href={canonicalUrl} />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/favicons/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicons/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicons/favicon-16x16.png"
      />
    </Head>
  );
}
