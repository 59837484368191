import ProductItem from "./ProductItem";
import React from "react";
import ArrowPrevious from "../Icons/ArrowPrevious";
import ProductItemSkeleton from "./ProductItemSkeleton";
import useTranslation from "next-translate/useTranslation";

export default function ProductList({ products, storeCode }) {
  const { t } = useTranslation("home");

  return (
    <div className="mt-20">
      <div className="bg-tertiary h-0.5 w-full">
        <span className="absolute left-1/2 -translate-x-1/2 -translate-y-1/2 block rounded-full bg-white p-4">
          <ArrowPrevious className="h-4 w-4 text-secondary -rotate-90 transform" />
        </span>
      </div>
      <div className="mt-8">
        <h2 className="text-lg lg:text-xl text-center mb-10 lg:mb-16">
          {t("title_model_choice")}
        </h2>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 lg:gap-16 pb-16">
          {products ? (
            products.map((product) => {
              return (
                <div key={product.sku}>
                  <ProductItem product={product} storeCode={storeCode} />
                </div>
              );
            })
          ) : (
            <>
              <ProductItemSkeleton />
              <ProductItemSkeleton />
              <ProductItemSkeleton />
            </>
          )}
        </div>
      </div>
    </div>
  );
}
